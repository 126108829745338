<template lang="">
     <section class="mt-12 bg-gray-50 text-gray-600 body-font overflow-auto">
    <div class="container px-5 py-24 mx-auto">
      <div class="">
        <h1 class="font-bold text-2xl text-center text-gray-900">Terms of Service Agreement</h1>
        <p class="mt-1"><em>Last updated January 6, 2023. Replaces all prior versions.</em></p>
        <p class="mt-1">These Alkaid Terms of Service (“<strong>Terms of Service</strong>”) govern your use of the Alkaid website, customer support, and software that we include as part of the Services, as well as any related applications, sample files, scripts, source code, instruction sets and related documentation (collectively, “<strong>Software</strong>”).</p>
        <p class="mt-1">The Services and Software are offered by Alkaid Pvt. Ltd. (“<strong>Alkaid</strong>”, “<strong>we</strong>” or “<strong>us</strong>”), and you accept these Terms of Service by clicking an “agree” or similar button, where that option is provided by Alkaid, or if you use or access the Services or Software. Your agreement to these Terms of Service also binds your authorized users, your company or organization. If you do not agree to these Terms of Service, do not accept them or do not continue using the Services or Software.</p>
        <p class="mt-1">We may also provide software or other materials subject to a separate license agreement, and that separate license agreement will govern the use of that software or other materials in the event of a conflict with these Terms of Service. Any such separate license agreement may be indicated in the license, notice, or readme files distributed with the applicable software or other materials or in related documentation.</p>
        <p class="mt-1">If you have entered into another agreement with us concerning specific Services or Software, then the terms of that agreement control where it conflicts with these Terms of Service.</p>
        <p class="mt-1">Alkaid reserves the right to update and change the Terms of Service at its discretion without notice. Any new Alkaid features in addition to the current Services or Software will be subject to the Terms of Service. Continued use of the Services or Software after any such changes will constitute your consent to such changes.</p>
        <p class="mt-1">You can review the most current version of the Terms of Service at any time at:&nbsp;<a href="https://ledgerflow.cloud/terms">https://ledgerflow.cloud/terms</a></p>
        <p class="mt-1">Violation of any of the terms below may result in immediate termination of your Service account.</p>
        <p class="mt-1"><strong>Services Account Terms</strong></p>
        <ul>
          <li>You do not need to create an account to download the software that we provide.</li>
          <li>If you wish to use Alkaid or any other Service we offer, you must create an account for Services with us, and pay the applicable fees or be part of a fee-paying customer.</li>
          <li>Accounts registered by automated methods are not permitted and will be deleted immediately without notice.</li>
          <li>You must provide your legal full name, a valid email address, and any other pertinent information requested in order to complete the account sign-up process.</li>
          <li>You are responsible for maintaining the security of your account and password. Alkaid cannot and will not be liable for any loss of information or security breaches that result from your failure to comply with this security obligation.</li>
          <li>You are fully responsible for all activity that occurs under your account.</li>
          <li>You may not use the Services or Software for any illegal activities. You must not, in your use of the Services or Software, violate any laws in your jurisdiction (including but not limited to copyright laws). Should you engage in any illegal activities through the Services, you agree to hold Alkaid blameless and not liable for any damages or crimes that may occur as a result. Without limiting its remedies, Alkaid may also immediately shut down and cancel your account and delete all data in it.</li>
          <li>You understand that Alkaid uses third party payment processors to handle payment transactions, and that all monetary transactions made through your use of the Services or Software are subject to any fees the payment processor may charge.</li>
        </ul>
        <p class="mt-1" id="refund-policy"><strong>Payment and Refunds Terms</strong></p>
        <ul>
          <li>A valid credit card or debit card or UPI id is required for making payment for Services or Software. A credit card or debit card is not required upon initial sign up.</li>
          <li>UPI Transactions cannot be cancelled after initiating the transaction. If the User has entered the wrong number/VPA ID, then the Company is not a liable party in the transaction.</li>
          <li>All UPI transactions are real-time and if there is any delay in the end of the transaction then the User can check with support team of Alkaid at hello@ledgerflow.cloud after 30 mins cool period.</li>
          <li>If the User has any issues related to transactions linked with Debit/Credit card, then the User must report the same to the Company’s call center +919072424740 or Via mail hello@ledgerflow.cloud</li>
          <li>All refunds will be processed within 14 days by following the validation of the transaction from case to case basis in the given legal framework.</li>
          <li>Accounts are subject to monthly or yearly fees, unless otherwise agreed with Alkaid. All fees are payable in advance and are non-refundable.</li>
          <li>All fees exclude any taxes, levies, or duties imposed by taxing authorities, and you will be responsible for payment of all such taxes, levies, or duties.</li>
        </ul>
        <p class="mt-1"><strong>Account Deletion and Termination</strong></p>
        <ul>
          <li>If you wish to cancel your subscription for Services or Software, it is your responsibility to ensure that your subscription has been properly canceled. An email requesting a subscription to be canceled is not considered a cancellation. You can cancel your subscription at any time from the "Account" section within the Services.</li>
          <li>If you wish to delete your account you may also initiate deletion through the “Account” section within the Services. If you initiate account deletion, all account data (such as your email, name and billing information) is immediately deleted, and all related application data is also immediately removed from the Service. This information&nbsp;<em>cannot be recovered</em>&nbsp;once your account is deleted. Data on your local machine is not deleted.</li>
          <li>Your deletion will be effective immediately once initiated. You will not be charged again from that point forward. However, you will not receive a refund for amounts previously paid.</li>
          <li>Alkaid has the right to suspend or terminate your account and refuse any and all current or future use of the Services for any reason at any time. Alkaid, in its sole discretion, reserves the right to refuse to provide the Services or Software to anyone for any reason at any time.</li>
          <li>Our warranty disclaimers, our limitations of liability, our ownership of the Services and Software, and the dispute resolution provisions of these Terms of Service will survive any expiration or termination of these Terms of Service.</li>
        </ul>
        <p class="mt-1"><strong>Privacy</strong></p>
        <ul>
          <li>For information about how we collect, use, share or otherwise process information about you, please see our Privacy Policy at&nbsp;<a href="https://ledgerflow.cloud/privacy-policy.html">https://ledgerflow.cloud/privacy-policy.html</a></li>
          <li><em>Application usage information</em>. We may provide you with the option to share information with us about how you use our desktop applications. This option is “off” by default, and it is your choice if to opt in to send us this information in the desktop application user interface. This information allows us to help improve product quality and features.</li>
          <li><em>Services data.</em>&nbsp;As described more fully in our Privacy Policy, if you use the Services, any content or data you transmit through the Services is encrypted locally by your instance of the Software, before transmission, and requires a unique key to unencrypt. This unique key is not known by, accessible to, or recoverable by Alkaid or any of its personnel. As a result, if you lose or forget your key, Alkaid has no way to recover it for you. You will still have access to your data locally, but you may permanently lose the ability to upload or transmit data through the Services. IT IS YOUR RESPONSIBILITY TO SAFEGUARD YOUR ENCRYPTION KEY, and we will not be liable for any loss or damage you suffer if you permanently forget or lose your encryption key.</li>
        </ul>
        <p class="mt-1"><strong>Modifications to the Services and Prices</strong></p>
        <ul>
          <li>Alkaid reserves the right at any time, with or without notice, to modify or discontinue the Services or Software, temporarily or permanently.</li>
          <li>Alkaid reserves the right to change the prices of any and all Services or Software at any time.</li>
          <li>Alkaid will not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Services or Software.</li>
        </ul>
        <p class="mt-1"><strong>Intellectual Property and Content Ownership</strong></p>
        <ul>
          <li>The Services and Software themselves including the use of the Alkaid name, logos, domain names and other distinctive brand features are protected by copyright and other laws: © Alkaid., All rights reserved.</li>
          <li>We grant you a limited, personal, non-transferable, non-exclusive license to access and use the Services and Software, subject to the terms of these Terms of Service. We (and our licensors) are the owner of all right, title and interest in the Services or Software.</li>
          <li>You retain your rights to any content or data you submit, post or display on or through the Services. Alkaid claims no ownership to any data you upload or transmit through the Services.</li>
        </ul>
        <p class="mt-1"><strong>General Conditions</strong></p>
        <ul>
          <li>Your use of the Services and Software is at your own risk. The Services and Software are provided on an "as is" and "as available" basis.</li>
          <li>Technical support is available to account holders and is only available via Alkaid social channels and email.</li>
          <li>You acknowledge and understand that Alkaid uses third party vendors to provide the necessary hardware, software, networking, storage, and related technology required to run the Services.</li>
          <li>You must not modify, adapt or hack the Services or modify another website so as to falsely imply that it is associated with the Services, Software, Alkaid.</li>
          <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services.</li>
          <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Alkaid employee, member, or officer will result in immediate account termination and potential criminal charges.</li>
          <li>Alkaid does not warrant that (i) the Services or Software will meet your specific requirements, (ii) the Services or Software will be uninterrupted, timely, or error-free, (iii) the quality of any products, services, information, or other material purchased or obtained by you through the Services or Software will meet your expectations.</li>
          <li><strong>You expressly understand and agree that we will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Alkaid has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services or Software; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services or Software; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the Services; (v) or any other matter relating to the Services or Software.</strong></li>
          <li><strong>Except where limited by law, our total liability arising out of or related to these Terms of Service is limited to US $50 or the aggregate amount you paid for access to the Service and Software during the three-month period preceding the event giving rise to the liability, whichever is larger. This limitation will apply regardless of the form or source of claim or loss, whether the claim or loss was foreseeable, and whether a party has been advised of the possibility of the claim or loss.</strong></li>
          <li>You expressly understand and agree that Alkaid will not be liable for any payments and monetary transactions that occur through your use of the Services or Software. You expressly understand and agree that all payments and monetary transactions are handled by the 3rd party payment platform the Service uses (as of the date of these Terms of Service, Stripe (<a href="https://stripe.com/">https://stripe.com</a>)). You agree Alkaid will not be liable for any issues regarding financial and monetary transactions between you and any other party.</li>
          <li>The failure of Alkaid to aggressively enforce any right or provision of the Terms of Service will not be construed as a waiver of such right or provision. Unless there is a separate written agreement between you and Alkaid covering the Services and Software, the Terms of Service outlines the entire agreement between you and Alkaid concerning the Services and Software and supersedes any prior agreements between you and Alkaid including prior iterations of the Terms of Service.</li>
          <li>If any provision of these Terms of Service is held invalid or unenforceable for any reason, the other provisions of these Terms of Service will continue in full force and effect.</li>
          <li>You may not use the Services or Software in violation of export control or sanctions laws of the India or any other applicable jurisdiction.</li>
          <li>You may send notices to use at the following address: 68/1520, 2, CM MATHEW BROTHERS ARCADE, CHAKKORATHUKULAM, CALICUT, KERALA, INDIA 673014, Attention: Legal Department.</li>
          <li>We may notify you by email, postal mail, postings within the Services, or other legally accepted means.</li>
          <li>Except to the extent applicable law provides otherwise, these Terms of Service are governed by the laws of India, without regard to conflict of law provisions. You and Alkaid agree to submit to the exclusive jurisdiction and venue of the courts located in the City and District of Calicut.</li>
        </ul>
        <p class="mt-1"><strong>Changes</strong></p>
        <p class="mt-1">If we decide to change these Terms of Service, we will post those changes on this page. If you do not agree to the changed Terms of Service, then your sole remedy will be to stop using the Services or Software.</p>
        <p class="mt-1">Summary of changes so far:</p>
        <p class="mt-1"><strong>May 2, 2023</strong><br>Updating refund policy.</p>
        <p class="mt-1"><strong>January 6, 2023</strong><br>Original version</p>
      </div>
    </div>
        
  </section>
</template>
<script>
export default {
  name: 'terms'
}
</script>
<style lang="">
    
</style>