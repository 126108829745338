<template lang="">
    <section class="mt-12 bg-gray-50 text-gray-600 body-font overflow-auto">
    <div class="container px-5 py-24 mx-auto">
      <div class="">
        <h1 class="font-bold text-2xl text-center text-gray-900">Privacy Policy</h1>
        <p class="mt-1">Alkaid’s&nbsp;<a href="https://ledgerflow.cloud/privacy-policy.html">Privacy Policy</a>&nbsp;applies to information collected or submitted during use of Alkaid's website and desktop applications.</p>
        <h2 class="mt-1 font-bold text-lg text-center text-gray-900">Information We Collect</h2>
        <p>When creating a Alkaid account, you will be asked to enter an email address and password. Email addresses are used during sign-in in and for receiving occasional updates initiated by you or your team members. These updates include, but are not limited to, account activation, team invitations, and payment receipts.</p>
        <h3 class="mt-1 font-semibold text-md">Secure Data</h3>
        <p>Secure Data is data we are not capable of decrypting under any circumstance and is used for the operation of our data synchronisation service. This data is encrypted using cryptographic keys that only you possess. We never receive copies of unencrypted Secure Data.</p>
        <h3 class="mt-1 font-semibold text-md">Service Data</h3><p>Service Data is data generated by using the applications, your account, and processing your payments. We retain the minimum amount of Service Data to operate our services.</p><p>Service Data is kept confidential. It is visible to our staff and includes, but is not limited to, email address, full name, billing information, server logs, client IP address, and stats on application usage.</p>
        <h2>Analytics Data</h2><p>Alkaid’s website and (if you opt-in) applications collect information to improve our Services.</p>
        <h2 class="mt-1 font-semibold text-md">Information Usage</h2>
        <p>We use the information we collect to operate and improve our website, applications, and provide customer support. We do not share personal information with outside parties, except to provide specific services.</p>
        <p><strong>Customer Support System</strong><br>Data needed to provide customer support is collected by us only.</p>
        <p>Any information you choose to send us through email or our customer support system may pass through and be stored on a variety of intermediate services.</p>
        <p><strong>Payment Processor</strong><br>Data needed to process payments is collected by Stripe, Inc., which conforms to a U.S.-E.U. Privacy Shield Framework. See&nbsp;<a href="https://stripe.com/privacy-shield-policy">https://stripe.com/privacy-shield-policy</a></p>
        <p><strong>Third-Party Data Processors</strong><br>Your Secure Data and Service Data are held by third party data processors, who provide us with hosting and other infrastructure services.</p>
        <p><strong>Sub-processor: Purpose<br></strong>Google Analytics: Website and application analytics<br>Google Cloud: Cloud infrastructure hosting<br>Sendgrid: Transactional email service<br>Stripe: Payment processing<br></p>
        <h2 class="mt-1 font-semibold text-md">Your Responsibilities for Protecting Your Data</h2>
        <p>When you create an Alkaid account you create a password. For your protection, you should create a strong and unique password to ensure that it is not easily guessed. You should also keep a copy in a safe place because future access to your Secure Data depends on your password.</p>
        <p>We will never ask you for your password at any time and you should never send it to us.</p>
        <h2 class="mt-1 font-semibold text-md">Your Right to Knowing to What We Know</h2>
        <p>You have the right to know what we know about you and to see how that data is handled. You may request a screenshot of what we can see about you in our back office systems. However, to protect customer privacy, such requests must be carefully authenticated beyond demonstrating control of the customer’s email address.</p>
        <h2 class="mt-1 font-semibold text-md">Your Right to Have Your Data Erased</h2>
        <p>Account owners have the right to instruct us to remove data permanently from our systems. To ensure that no one’s data is deleted without their consent, you must first delete your account through an authenticated session. Once your account is deleted, it cannot be recovered.</p>
        <p>Disaster recovery and data availability requirements mean that Alkaid has a legitimate interest in maintaining secure and immutable backups. Erasure requests will leave those backups untouched, and we will only remove data from backups if legally compelled to.</p>
        <h2 class="mt-1 font-semibold text-md">Cookies and Tracking</h2>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
        <p>Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
        <p>Examples of Cookies we use: session cookies (we use these cookies to operate our Service) and preference cookies (we use these cookies to remember your preferences and various settings).</p>
        <h2 class="mt-1 font-semibold text-md">Information for European Union Customers</h2>
        <p>By using our Service and providing your information, you authorize us to collect, use, and store your information outside of the European Union.</p>
        <h2 class="mt-1 font-semibold text-md">Consent for Underage Enrollment</h2>
        <p>Those under the age of 16 may not use the services without the consent or authorization of their parent or legal custodian. Team owners are responsible for that authorization when they add someone under the age of 16 to an account.</p>
        <h2 class="mt-1 font-semibold text-md">Disclosure</h2>
        <p>We will comply with applicable law with respect to providing Service Data and encrypted Secure Data to law enforcement agencies. If permitted, we will notify you of such a request and whether or not we have complied. Your Secure Data remains encrypted with keys which we do not posses, and so we can only hand over Secure Data in encrypted form.</p>
        <h2 class="mt-1 font-semibold text-md">Breach Notification</h2>
        <p>If the confidentiality of customer data is breached, we will publicly disclose the nature of the risk and provide a transparent account of the events without undue delay. At a bare minimum, we will inform the applicable supervisory authorities as required by law and regulation.</p>
        <h2 class="mt-1 font-semibold text-md">Contacting Us</h2>
        <p>If you have questions regarding this privacy policy, you may email&nbsp;<a href="mailto:hellow@ledgerflow.cloud">hello@ledgerflow.cloud</a>. Please note that account deletion should be done within the account dashboard and not via email requests, for security reasons.</p>
        <h2 class="mt-1 font-semibold text-md">Changes</h2>
        <p>If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:</p>
        <p><strong>January 6, 2023</strong><br>Original version</p>
      </div>
    </div>
        
  </section>
</template>
<script>
export default {
  name: 'privacy'
}
</script>
<style lang="">
    
</style>